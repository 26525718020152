<template>
    <div style="position:relative;" class="custom-feature-container">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Field Staff Real Time Tracking: Generate Automated Timesheets</h1>
                    <p>In today's diverse and ever-evolving work landscape, real time visibility into field staff activities holds significant importance across various industries, particularly those that offer remote and fieldwork opportunities. Industries with remote or field staff such as construction, cleaning, electrical, <a href="https://recordtime.com.au/blog/record-time-for-plumbing-business">plumbing</a>, and heavy civil, face challenges when it comes to employee monitoring and managing their workforce's time. The absence of direct supervision makes tracking of field workers challenging. However, Record TIME, provides real time insights into staff activities, progress, and performance, facilitating effective remote team management and fostering accountability.
                        <br/><br/>For businesses that bill clients based on hours worked or projects completed, tracking their time becomes indispensable. With Record TIME, you can generate accurate automated timesheets minimising disputes, and ensuring accurate invoice compensation for both clients and staff. Certainly, businesses can streamline their job sites, enhance productivity, and achieve greater transparency in their workforce management, improving overall efficiency.</p><br/>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/blog/enhancing-workforce-management-in-the-cleaning-industry-with-an-innovative-employee-time-clock-software')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
                    <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn><br/><br/>
                </div>
            </div>
            <div  class="right-banner">
                <img :src="`${publicPath}assets/features-image/work-time-sheet.webp`" alt="staff time tracking with the record time"  style="max-width: 100%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        
        <v-container><br/>
            <div class="hidden-sm-and-down"><br/><br/><br/><br/></div>
            <div>
                <h2 style="text-align: center;font-size: 28px;">Field Staff Real Time Tracking From Anywhere: Empowering Remote and Field Work Industries</h2><br/><br/>
                <div class="left-blog1">
                    <img :src="`${publicPath}assets/features-image/boss-tracking-employee-record.webp`" alt="boss tracking field staff's timesheets" style="max-width: 80%;">
                </div>
                <div class="right-blog1">
                    <h3>Download Record TIME</h3>
                    <p>Our field staff real time tracking software is available on Android and iOS platforms. Users can also visit our website, recordtimeapp.com.au, to track work hours.</p>
                    <br/>
                    <br/>
                    <h3>Track Time to Project and Task</h3>
                    <p>Once you have the Record TIME mobile app installed on your device, you can easily assign and monitor employee time spent on specific projects and tasks. Additionally, this automated time tracking app enables you to create custom reporting on hours worked and supports task management.</p>
                    <br/>
                    <br/>
                    <h3> Generate Accurate Time Sheet</h3>
                    <p>Particularly, Record TIME offers a convenient way to create timesheets that summarise staff's time on different task or project. Furthermore, you can generate these timesheets on a weekly, monthly, or custom basis, depending on what works best for you.</p>
                </div>
                <div style="clear:both;"></div>
            </div>
            <div class="hidden-sm-and-down"><br/><br/><br/></div>
            
            <div class="gray-container"><br/>
                <h3>Benefits of Record TIME’s Field Staff Real Time Tracking Feature</h3>

                <div style="display: inline-table;margin-top:30px" class="list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/accuracy.webp`" alt="accuracy and transparancy">
                            <h2>Accuracy and Transparency</h2>
                            <p>Record TIME guarantees real time tracking, excluding manual errors. It offers transparent records that are simple for management and staff to review.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/payroll.webp`" alt="streamlined payroll process">
                            <h2>Streamlined Payroll Process</h2>
                            <p>The software integrates with payroll systems, simplifying the payroll process. Consequently, this tracking software significantly reduces administrative work and potential errors.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/increase-productivity.webp`" alt="increase productivity with record time">
                            <h2>Increase Productivity</h2>
                            <p>By tracking employee time, you can identify obstacles, errors, and unproductive field activities. These data aid in workflow optimisation, efficient resource allocation, and increased productivity in general.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/labor-laws.webp`" alt="compliance with labor laws">
                            <h2>Compliance with Labor Laws</h2>
                            <p>Furthermore, we help you to ensure compliance with labor laws and regulations in terms of record-keeping, breaks and overtime. The software creates accurate reports that are useful for legal and auditing purposes.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/project-management.webp`" alt="enhanced project management">
                            <h2>Enhanced Project Management</h2>
                            <p>In addition, you can keep track of the time spent on various jobs and projects with our time tracking software. It will help in project management, resource allocation, and estimating future projects more accurately.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/employee-accountability.webp`" alt="staff accountability">
                            <h2>Staff Accountability Towards Time</h2>
                            <p>With clear time tracking records, staff are more accountable for their work hours. Moreover, it promotes transparency and discourages time theft, leading to a more productive and responsible field service.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/Billing.webp`" alt="client projects and billing">
                            <h2>Reporting on Client Projects and Billing</h2>
                            <p>Record TIME enables accurate client billing based on actual hours worked. It provides detailed reports that help in invoicing, client transparency, and project beneficial analysis.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/time-sheet.webp`" alt="staff time sheet system">
                            <h2>Simple Time Sheet System</h2>
                            <p>Record TIME provides an organised timesheet system that simplifies entering and managing staff hours. Especially, it offers user friendly interfaces where staff can easily submit their work hours, breaks, and any additional information required.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/features-image/clock-in.webp`" alt="easy clock-in and clock-out">
                            <h2>Easy Clock-In and Clock-Out for Accurate Time Tracking</h2>
                            <p>Above all, Record TIME offers a user-friendly interface for staff to clock in and clock out effortlessly. This simplicity promotes accurate time entry and reduces time spent on administrative duties.  </p>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/>
            <img :src="`${publicPath}assets/features-image/manage-employees-rojects-and-ayroll.webp`" alt="managing staff, projects, and payroll"  style="margin: 30px auto; max-width: 100%;display: block; "><br/><br/>

            <h2>Manage Staff, Projects, and Payroll</h2><br/>
            <p>Record Time is a comprehensive field staff real time tracking software designed to serve businesses across various industries. Moreover, its comprehensive features give businesses a centralised platform to efficiently manage and optimise their workforce. Some of the features include digital docketing, pre start checklists (<a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist">excavator</a>, vehicle, forklift, truck, ewp), proof of work, and job scheduling. 
                <br/><br/>Importantly, you can easily report any incidents in real time using our digital forms and dockets template. Our time tracking software is suitable for businesses of all sizes. It offers a great solution to manage field operations and the overall business process for small businesses and big enterprises.
           </p><br/>
           
            <br/><br/>
            <h2>Industries That Use Record TIME for Field Staff Real Time Tracking</h2><br/>
            <img :src="`${publicPath}assets/features-image/industries-that-use-record-time.webp`" alt="Industries Using Record TIME for Staff Time Tracking" style="max-height: 800px;width: 100%; object-fit: contain;width: 100%;transform: none;">
            <br/><br/> 
        </v-container>

        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>
    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 60%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 40%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .custom-feature-container{
        .text-section h1{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
        .banner-img{
            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 40px !important;
                height: auto !important;
                margin-top: 20px;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 20px !important;
            }
        }
    }
    .customH2{
        position: relative;
        display: inline-block;
        padding-right: 55px;
        margin-bottom: 15px;
        color: #00C8D8;
        img{
            position: absolute;
            right: 0px;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        text-align: center;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        img{
            margin: 0px auto 10px;
            display: block;
        }
        p{
            margin-top: 15px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: center;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 800px);
      padding-right: 15px;
      text-align: right;
    }
    .right-blog1{
      width: 800px;
      float:right;
      img{
        max-width: 500px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Field Staff Real Time Tracking for Businesses | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Field staff real time tracking for various industries to achieve transparency and enhanced productivity along with workforce management.'},
      {name: 'keyword', content: 'field staff real time tracking'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/field-staff-real-time-tracking' }
    
    ]
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
            window.open(url, '_blank');
        },
        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
